<template lang="">
<div>
    <p>Thêm loại sản phẩm</p>
    <el-form style="max-width: 600px" :model="dataType" label-width="auto" class="demo-dynamic">
        <el-form-item prop="Name" label="Tên loại sản phẩm" :rules="[{
          required: true,
          message: 'Không được để trống tên loại sản phẩm',
          trigger: 'blur',
        }]">
            <el-input v-model="dataType.Name" />
        </el-form-item>
        <el-form-item prop="Img" label="Img" >
            <el-input v-model="dataType.Img" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="status==='update' ? updateType() : createType() ">{{status==='update'? 'Cập nhật' : 'Thêm sản phẩm' }}</el-button>
            <el-button @click="back()">Quay lại</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
import {
    getListType,
    addType,
    updateType
} from '../../../api/api'
import moment from 'moment';
import {
    ElMessage
} from 'element-plus';

export default {
    name: "TypeForm",
    props: ['status'],
    created() {
        if (this.status === 'update') {
            this.setData()
        }
    },
    data() {
        return {
            dataType: {
                'Name': '',
                'Link': '',
                'Img': '',
                'timeCreate': moment().format("DD-MM-YYYY hh:mm:ss")
            },
            id: this.$route.query.id
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async setData() {
            const response = await getListType(this.id)
            this.dataType = response.data            
        },
        async updateType() {
            try {
                const response = await updateType(this.id, this.dataType)                
                if (response.data.success === true) {
                    ElMessage({
                        type: 'success',
                        message: 'Cập nhật thành công',
                    })
                    this.back()
                }
            } catch (error) {
                console.log(error);
            }
        },
        async createType() {
            try {
                const response = await addType(this.dataType)
                if (response.status === 201) {
                    ElMessage({
                        type: 'success',
                        message: 'Thêm thành công',
                    })
                    this.back()
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style lang="">

 </style>
