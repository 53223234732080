<template lang="">
    <div>
        <p>Phong Home</p>
    </div>
</template>
<script>

export default {
    name: "AdminHome",
}
</script>
<style lang="">
    
</style>