<template lang="">
    <ProductForm status="update" />
</template>
<script>
import ProductForm from './ProductForm.vue';
export default {
    name: "ProductUpdate",
    components: {
        ProductForm
    }
}
</script>