<template lang="">
<div class="layout__user">
    <div class="menu__over--user">
        <div class="container header__menu">
            <div class='logo__menu' @click='returnHome()'>Tạp Hóa Bà Thủy</div>
            <!-- <div class="menu__item--user" v-for="item in menu" :key="item.name" @click='clickMenu(item)'>{{item.name}}</div> -->
        </div>
    </div>
    <div>
        <router-view />
    </div>
    <div></div>
    <div class="footer__user--over">
        <div class="container">
            <p class="footer__name cursor-pointer font-medium text-blue-500" @click='returnHome()' >Tạp hóa bà Thủy</p>
            <div class='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <p class="footer__des">Đa dạng nguồn hàng giá tốt cho người tiêu dùng</p>
                <div class='mt-4'>
                    <div class="footer__des--over">
                        <el-icon>
                            <Location />
                        </el-icon>
                        <p>Xóm 4, thôn Hậu Trung 1, xã Hồng Bạch, huyện Đông Hưng, tỉnh Thái Bình</p>
                    </div>
                    <a class="footer__des--over cursor-pointer" href='tel:038818754'>
                        <el-icon>
                            <Phone />
                        </el-icon>
                        <p>038818754</p>
                    </a>
                    <div class="footer__des--over">
                        <el-icon>
                            <Message />
                        </el-icon>
                        <p>nguyenthithuy1969@gmail.com</p>
                    </div>
                </div>
            </div>
            <p class='text-center w-full py-8 text-lg '>Copyright © 2024 taphoabathuy.com. All rights reserved.</p>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref
} from 'vue';
import {
    useRouter
} from 'vue-router';

export default {
    setup() {
        const router = useRouter();

        const menu = ref([{
                name: 'Trang chủ',
                link: '/',
                action() {
                    router.push('/');
                }
            },
            {
                name: 'Sản phẩm',
                link: '/product',
                action() {
                    router.push('/product');
                }
            },
            {
                name: 'Tin tức',
                link: '/news',
                action() {
                    router.push('/news');
                }
            }
        ]);

        const clickMenu = (item) => {
            item.action();
        }

        const returnHome = () => {
            router.push('/');
        }

        return {
            menu,
            clickMenu,
            returnHome
        }
    }
}
</script>

<style lang="css">
@keyframes colorChange {
    0% {
        text-shadow: 2px 2px 8px #FF0000;
    }

    25% {
        text-shadow: 2px 2px 8px #00FF00;
    }

    50% {
        text-shadow: 2px 2px 8px #0000FF;
    }

    75% {
        text-shadow: 2px 2px 8px #FFFF00;
    }

    100% {
        text-shadow: 2px 2px 8px #FF0000;
    }
}

.logo__menu {
    font-size: 44px;
    font-weight: 700;
    margin-right: 250px;
    animation: colorChange 4s infinite;
    cursor: pointer
}

@media only screen and (max-width: 600px) {
    .logo__menu {
        margin: 0;
        font-size: 32px
    }
}
</style>
