<template lang="">
    <ProductForm status="create" />
</template>

<script >
import ProductForm from './ProductForm.vue';
export default {
    name: "ProductCreate",
    components: {
        ProductForm
    }
}
</script>