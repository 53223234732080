<template lang="">
    <div>
        <p>Không tìm thấy gì cả</p>
    </div>
</template>
<script>
export default {
    name: "NotFound"
}
</script>
<style lang="">
    
</style>