<template lang="">
    <TypeForm status="create" />
</template>

<script >
import TypeForm from './TypeForm.vue';
export default {
    name: "TypeCreate",
    components: {
        TypeForm
    }
}
</script>
