export default {
    methods: {
        formatMoney(item) {
            if(item) {
                let variable = item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                return `${variable}đ`;
            }
            return ''
        }
    }
}