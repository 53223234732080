<template lang="">
<div class="container">
    <div class='over__detail mt-16'>
        <div class='detail__img'>
            <img :src="`${baseURL}${baseImg}${dataProduct.Img}`" :alt="`${dataProduct.Name}`" class='detail__img--item' />
        </div>
        <div class='detail__info'>
            <p class='detail__name'>{{dataProduct.Name}}</p>
            <p class='mt-1'>Tình trạng: <span class='detail__des'>Còn hàng</span></p>
            <p class='mt-1'>Số lượng: <span class='detail__des'>{{dataProduct.Quantity}}</span></p>
            <p class='mt-1'>Danh mục: <span class='detail__des'>{{dataProduct.Category}}</span></p>
            <p class='mt-1 promotionalPrice'>{{ formatMoney(dataProduct.Price)}} <span :class='dataProduct.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{ formatMoney(dataProduct.PromotionalPrice)}}</span></p>
            <p class='mt-1'>Khuyến mãi: </p>
            <p class='mt-1 fw-7'>Thông tin: </p>
            <p class='mt-1'>{{dataProduct.Description}}</p>
        </div>
    </div>
    <div class='other__product mb-16'>
        <p class='mt-8 fw-5' style='fontSize: 20px'>Các sản phẩm khác:</p>
        <p v-if='listOrtherProduct.length === 0'>Hiện tại sản phẩm loại này đang không còn. Cửa hàng sẽ cập nhật sản phẩm mới sau.</p>
        <el-row v-else :gutter="10" class='my-4'>
            <el-col v-for="(i) in listOrtherProduct" :key="i.ID" :span="6">
                <div class='product__over' @click='clickProduct(i)'>
                    <div class='view__discount' v-if='i.promotionalPrice'>
                        -{{ ((1-(i.PromotionalPrice/i.Price))*100).toFixed(0)}}%
                    </div>
                    <div class='product__view--img'>
                        <img :src="`${baseURL}${baseImg}${i.Img}`" :alt="`${i.Name}`" class='product__img' />
                    </div>
                    {{i.Name}}
                    <div class='product__price--view'>
                        <p class='promotionalPrice'>{{formatMoney(i.PromotionalPrice)}}</p>
                        <p :class='i.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{formatMoney(i.Price)}}</p>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</div>
</template>

<script>
import {
    baseURL,
    baseImg
} from '@/assets/variable';
import formatMoney from '@/mixin.js'
import {
    getListProduct,
    getOrtherProduct
} from '@/api/api'

export default {
    name: "DetaiProduct",
    mixins: [formatMoney],
    watch: {
        '$route.query.id': {
            immediate: true, // Đảm bảo watch được gọi ngay khi component được tạo
            handler(newId, oldId) {
                if (newId !== oldId) {
                    this.id = newId; // Cập nhật lại giá trị id trong data
                    this.resetView(); // Gọi lại resetView để fetch dữ liệu mới
                }
            }
        }
    },
    data() {
        return {
            id: this.$route.query.id,
            dataProduct: {},
            baseURL,
            baseImg,
            listOrtherProduct: []
        }
    },
    created() {
        this.setData()
        this.getDataOrther()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        resetView() {
            this.setData()
            this.getDataOrther()
        },
        async setData() {
            const response = await getListProduct(this.id)
            this.dataProduct = response.data
        },
        async getDataOrther() {
            const response = await getOrtherProduct(this.id)
            this.listOrtherProduct = response.data
        },
        clickProduct(e) {
            this.$router.push({
                path: '/product',
                query: {
                    id: e.ID
                }
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>
