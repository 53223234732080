<template lang="">
    <TypeForm status="update" />
</template>
<script>
import TypeForm from './TypeForm.vue';
export default {
    name: "TypeUpdate",
    components: {
        TypeForm
    }
}
</script>