<template>
<div class="login-form">
    <h2>Đăng nhập</h2>
    <form @submit.prevent="login">
        <div class="form-group">
            <label for="username">Tên người dùng:</label>
            <input type="text" id="username" v-model="data.username" required>
        </div>
        <div class="form-group">
            <label for="password">Mật khẩu:</label>
            <input type="password" id="password" v-model="data.password" required>
        </div>
        <button type="submit">Đăng nhập</button>
    </form>
</div>
</template>

<script>
import {
    login
} from "../../../api/api"

export default {
    data() {
        return {
            data: {
                username: '',
                password: ''
            }
        };
    },
    methods: {
        async login() {
            try {
                const response = await login(this.data);
                console.log(response.data);
                if (response.status === 200) {
                    this.$router.push('/adminThuy')
                }
            } catch (err) {
                console.log(err);
            }
        }
    }
};
</script>

<style scoped>
.login-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>
