import axios from 'axios';
// import { baseURL } from '@/assets/variable';

const baseURL = 'http://localhost:8000';

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    }
});

const apiAdmin = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWZlNDBiZDdlOGQ4Yjc2NTg3ZTFiZjEiLCJpYXQiOjE3MTEyODc5NzV9.rRcQLkEH2FXGR5BYiF0aQvicXFmLtkGnjLUo58FnHac'
    }
});

export const login = async (user) => {
    try {
        const response = await api.post(`/login`, user);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const getListType = async (id) => {
    try {
        const endpoint = id ? `/categorys/${id}` : '/categorys';
        const response = await api.get(endpoint);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const updateType = async (id, data) => {
    try {
        const response = await api.put(`/categorys/${id}`, data);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const addType = async (data) => {
    try {
        const response = await apiAdmin.post(`/categorys`, data);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const deleteType = async (id) => {
    try {
        const response = await apiAdmin.delete(`/categorys/${id}`);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const getListProduct = async (id) => {
    try {
        const endpoint = id ? `/products/${id}` : '/products';
        const response = await api.get(endpoint);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const getListProductByType = async () => {
    try {
        const response = await api.get(`/products-by-category`);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const getOneProduct = async (id) => {
    try {
        const response = await api.get(`/products/${id}`);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const updateProduct = async (id, data) => {
    try {
        const response = await api.put(`/products/${id}`, data);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const addProduct = async (data) => {
    try {
        const response = await apiAdmin.post(`/products`, data);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const deleteProduct = async (id) => {
    try {
        const response = await apiAdmin.delete(`/products/${id}`);
        return response;
    } catch (error) {
        console.error(error)
    }
};

export const getOrtherProduct = async (id) => {
    try {
        const response = await api.get(`/related-products/${id}`);
        return response;
    } catch (error) {
        console.error(error)
    }
};