<template lang="">
<div>
    <el-button @click="addProduct()">Thêm sản phẩm</el-button>
    <el-table :data="listProduct" style="width: 100%">
        <el-table-column prop="Name" label="Tên" width="180" />
        <el-table-column prop="Price" label="Giá" />
        <el-table-column prop="PromotionalPrice" label="Giá khuyến mãi" />
        <el-table-column prop="Quantity" label="Số lượng" />
        <el-table-column prop="Description" label="Mô tả" />
        <el-table-column prop="IDCategory" label="ID Danh mục" />
        <el-table-column prop="Status" label="Trạng thái" />
        <el-table-column prop="Img" label="Hình ảnh" />
        <el-table-column label="Thao tác">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.row)">Edit</el-button>
                <el-button size="small" type="danger" @click="handleDelete(scope.row)">Delete</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import {
    getListProduct,
    deleteProduct
} from '@/api/api';
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'

export default {
    name: "TypePage",
    data() {
        return {
            listProduct: []
        }
    },
    created() {
        this.callListProduct()
    },
    methods: {
        async callListProduct() {
            try {
                const response = await getListProduct()
                if (response.status === 200) {
                    this.listProduct = response.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        addProduct() {
            this.$router.push('/adminThuy/product-create')
        },
        handleEdit(e) {
            this.$router.push({
                path: '/adminThuy/product-update',
                query: {
                    id: e.ID
                }
            });
        },
        handleDelete(e) {
            ElMessageBox.confirm(
                    'Bạn có muốn xóa không?',
                    'Cảnh báo', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Hủy',
                        type: 'warning',
                    }
                )
                .then(async () => {
                    try {
                        const response = await deleteProduct(e.ID)
                        if (response.status === 200) {
                            ElMessage({
                                type: 'success',
                                message: 'Xóa thành công',
                            })
                            this.callListProduct()
                        }
                    } catch (err) {
                        ElMessage({
                            type: 'info',
                            message: 'Xóa thất bại',
                        })
                    }
                })
                .catch(() => {})
        }
    }
}
</script>
